import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/league-office.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Arrow from "../components/svg/arrow"

export default ({ location }) => {
    const { personnel, owners } = useStaticQuery(graphql`
    query LeagueOfficeQuery {
        personnel: file(name: {eq: "personnel"}) {
          childLeagueJson {
            advisor {
              name
              position
              image
              description
            }
            staff {
              name
              position
              image
              description
            }
          }
        }
        owners: allTeamsJson {
            edges {
              node {
                owner {
                  description
                  image
                  name
                }
              }
            }
          }
      }
    `)

    const staffList = personnel.childLeagueJson.staff
    const advisorList = personnel.childLeagueJson.advisor
    const ownerList = owners.edges

    const [activePersonnel, setActivePersonnel] = useState("staff")

    return (
        <Layout location={location} cssPageName="league-office">
            <SEO title="League Office" />
            <h1 className="title">League Office</h1>
            <div className="toggle-flex">
                <button className={`toggle-button${activePersonnel === "staff" ? " active" : ""}`} onClick={() => setActivePersonnel("staff")}>Staff <Arrow /></button>
                <button className={`toggle-button${activePersonnel === "owner" ? " active" : ""}`} onClick={() => setActivePersonnel("owner")}>Team Owners <Arrow /></button>
                <button className={`toggle-button${activePersonnel === "advisor" ? " active" : ""}`} onClick={() => setActivePersonnel("advisor")}>Advisory Board <Arrow /></button>
            </div>

            <section className="listing-container">
                <ul className="member-listing">
                    {activePersonnel === "owner" && ownerList.map(({ node }) => (
                        <ListMember
                            key={node.owner.name}
                            name={node.owner.name}
                            image={node.owner.image}
                            description={node.owner.description}
                            position={node.owner.position}
                        />
                    ))}

                    {activePersonnel === "staff" && staffList.map((member) => (
                        <ListMember
                            key={member.name}
                            name={member.name}
                            image={member.image}
                            description={member.description}
                            position={member.position}
                        />
                    ))}

                    {activePersonnel === "advisor" && advisorList.map((member) => (
                        <ListMember
                            key={member.name}
                            name={member.name}
                            image={member.image}
                            description={member.description}
                            position={member.position}
                        />
                    ))}
                </ul>
            </section>
        </Layout>
    )
}

const ListMember = ({ name, image, description, position }) => (
    <li className="list-member">
        <img className="member-image" src={image} alt={name} />
        <div className="content-container">
            <h2 className="member-name">{name}</h2>
            <h3 className="member-position">{position}</h3>
            <p className="member-description">{description}</p>
        </div>
        <hr />
    </li>
)