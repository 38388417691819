import React from 'react'

export default ({ orientation, color }) => {
    return (
        <>
            <svg className="arrow" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4L18 14.5L4 25" stroke={color || "white"} strokeWidth="7" strokeLinecap="round" />
            </svg>
        </>
    )
}
